import React, { useEffect, useState } from "react";
import "./course.scss";
import List from "./list";

const Course = () => {
    const [leads, setLeads] = useState()

    useEffect(()=>{
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        
        fetch("https://lingvela.store/form1/get", requestOptions)
            .then(response => response.json())
            .then(result => setLeads(result))
            .catch(error => console.log('error', error));
    },[])

  return (
    <div className="course-wrapper">
      <div className="head">
        <ul>
          <li>ID</li>
          <li>Ism Familiya</li>
          <li>Turli kursi</li>
          <li>Telefon Raqami</li>
          <li></li>
        </ul>
      </div>
      <div className="lists">
        {
                leads?.leads?.map((el, index) =>{
                    return <List key={el} name={el.form_fullname} phone={el.form_phonenumber} status={el.form_status} course={el.form_button} index={index +1} id={el.form_id} />
                })
            }
      </div>
    </div>
  );
};

export default Course;
