import React from 'react'
import './App.scss'
import { Link, Routes, Route, useLocation } from 'react-router-dom'
import Course from './course'
import Question from './question'
import Special from './special'

const App = () => {
  const location  = useLocation()
  const path = location.pathname
  
  return (
    <div className='admin-wrapper'>
      <div className='sidebar'>
        <ul>
          <li><Link className={path === '/' ?  'active' :''}  to={'/'} >Kurs uchun</Link></li>
          <li><Link className={path === '/special' ?  'active' :''} to={'/special'}>Maxsus taklif</Link></li>
          <li><Link className={path === '/questions' ?  'active' :''} to={'/questions'} >Savollar</Link></li>
        </ul>
      </div>
      <div className="main-page">
          <Routes>
            <Route path='/' element={<Course/>}/>
            <Route path='/questions' element={<Question/>}/>
            <Route path='/special' element={<Special/>}/>
          </Routes>
      </div>
    </div>
  )
}

export default App
