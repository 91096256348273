import React, { useEffect, useState } from "react";
import "./question.scss";
import ListQuestion from "./list";

const Question = () => {
    const [leads, setLeads] = useState()

    useEffect(()=>{
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        
        fetch("https://lingvela.store/form3/get", requestOptions)
            .then(response => response.json())
            .then(result => setLeads(result))
            .catch(error => console.log('error', error));
    },[])

  return (
    <div className="course-wrapper">
      <div className="head">
        <ul>
          <li>ID</li>
          <li>Ism Familiya</li>
          <li>Savollar</li>
          <li>Telefon Raqami</li>
          <li></li>
        </ul>
      </div>
      <div className="lists">
        {
                leads?.leads?.map((el, index) =>{
                    return <ListQuestion id={el.form_id} key={el} name={el.form_fullname} phone={el.form_phonenumber} status={el.form_status} course={el.form_text} index={index +1} />
                })
            }
      </div>
    </div>
  );
};

export default Question;
