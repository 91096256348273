/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import './list.scss'
import { useLocation } from 'react-router-dom';

const List = ({name, phone, status, course, index, id}) => {
  const [action , setAction] = useState(status)
  console.log(id);
  const location = useLocation()
  const path = location.pathname
  console.log(path);
  const statusUpdate = (action)=>{
    setAction(action)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "status": action
    });
    
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(`https://lingvela.store/${path === '/' ? 'form1' : path === '/special' ? 'form2' : ''}/status/update?id=${id}`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    }
  return (
    <div className='list-wrapper'>
        <ul>
            <li>{index}</li>
            <li>{name}</li>
            <li>{course}</li>
            <li>{phone}</li>
            <li>
                <button  className={action === 'pending' ? 'colorone' : ''} onClick={() => {
                  statusUpdate('pending')
                }}></button>
                <button className={action === 'complete' ? 'colortwo' : ''} onClick={() => {
                  statusUpdate('complete')
                }}></button>
                <button className={action === 'reject' ? 'colorthree' : ''} onClick={() => {
                  statusUpdate('reject')
                }}></button>
            </li>
        </ul>
    </div>
  )
}

export default List
